<template>
  <section class="flex space-x-3 md:space-x-4 items-center">
    <img :src="url" :alt="globalSetting?.heading" class="w-[2.6rem] md:w-20" />
    <section class="font-serif">
      <p class="text-primary font-serif font-bold text-xl leading-tight md:leading-none md:text-[1.875rem]">
        {{ globalSetting?.heading }}
      </p>
      <p class="text-primary uppercase text-xs md:text-base">
        {{ globalSetting?.subheading }}
      </p>
    </section>
  </section>
</template>

<script setup lang="ts">
const globalSetting = useGlobalSetting()
const fixUrl = useFixUrl()

const url = computed(() => fixUrl(globalSetting.value?.logo?.url))
</script>
